<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items justify-content-space-between marginBottom10">
          <div class="flex align-items">
            <el-input
              size="medium"
              class="width300 marginBottom10 marginRight10"
              v-model="searchData.name"
              auto-complete="off"
              :maxlength="12"
              clearable
              placeholder="请输入角色名称"
              @change="handleSearch"
            />
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
              >查询</el-button>
            </div>
            <div class="marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
              >重置</el-button>
            </div>
          </div>
          <div class="marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleCreate"
            >添加</el-button>
          </div>
        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
      :title="form.id ? '编辑' : '新增'"
      v-if="show"
      :visible.sync="show"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="handleSubmit"
      >
        <template slot="code">
          <div>系统生成</div>
        </template>
        <template slot="menuIds">
          <div class="width300">
            <el-tree
              ref="tree"
              :props="props"
              :data="menuRole"
              show-checkbox
              node-key="id"
              :default-expanded-keys="defaultCheckedKeys"
              :default-checked-keys="defaultCheckedKeys"
              @check-change="handleCheckChange"
            ></el-tree>
          </div>
        </template>
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      class="dialogPower"
      title="角色权限配置"
      v-if="showPower"
      :visible.sync="showPower"
      width="30%"
      top="10vh"
      :before-close="handleClosePower"
    >
      <el-tree
        ref="tree"
        :props="props"
        :data="menuRole"
        show-checkbox
        node-key="id"
        :default-expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        @check-change="handleCheckChange"
      ></el-tree>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :icon=" submitType ? 'el-icon-circle-check' : 'el-icon-loading' "
          type="primary"
          size="small"
          :disabled="!submitType"
          @click="handleSubmitPower"
        >{{ submitType ? '确 定' : '提交中' }}</el-button>
        <el-button
          icon="el-icon-circle-close"
          size="small"
          @click="handleClosePower"
        >取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  roleList,
  roleDelete,
  roleMenuTree,
  roleAdd,
  roleView,
  roleUpdate,
  roleMenuUpdate,
} from "@/api/system/role";
export default {
  name: "index",
  data() {
    return {
      page: {
        total: 0,
        currentPage: 1,
      },
      searchData: {
        name: "",
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "角色管理",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "角色",
            prop: "name",
          },
          {
            label: "员工数量",
            prop: "num",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      show: false,
      form: {},
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: "权限编码",
            prop: "code",
            span: 24,
          },
          {
            label: "角色名称",
            prop: "name",
            maxlength: 12,
            showWordLimit: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入角色名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "描述",
            prop: "roleDesc",
            type: "textarea",
            maxlength: 100,
            placeholder:'请输入',
            showWordLimit: true,
            span: 24,
          },
          {
            label: "",
            prop: "menuIds",
          },
        ],
      },

      showPower: false,
      props: {
        label: "name",
        value: "id",
        isLeaf: "leaf",
      },
      menuRole: [],
      powerId: "",
      defaultCheckedKeys: [],
      submitType: true,
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    this.getRoleMenuTree();
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      roleList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 搜索
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      this.searchData = this.$options.data().searchData;
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加角色
    handleCreate() {
      // console.log('添加角色')
      this.form = {}
      this.defaultCheckedKeys = []
      this.show = true;
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      const { data } = await roleView(row.id);
      this.defaultCheckedKeys = data.menuIds;
      this.form = data;
      this.show = true;
    },
    async getRoleMenuTree() {
      const { data } = await roleMenuTree();
      // console.log(data)
      this.menuRole = data || [];
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("是否删除此角色数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        roleDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 提交
    handleSubmit(form, done) {
      // console.log(form)
      if (form.id) {
        roleUpdate(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.onLoad();
              this.show = false;
              setTimeout(() => {
                this.resetForm();
              }, 200);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        roleAdd(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.onLoad();
              this.show = false;
              setTimeout(() => {
                this.resetForm();
              }, 200);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.show = false;
      setTimeout(() => {
        this.resetForm();
      }, 200);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        code: "",
        name: "",
        state: "",
        remarks: "",
      };
    },
    handleCheckChange() {
      console.log(this.$refs.tree.getCheckedKeys());
      this.form.menuIds = this.$refs.tree.getCheckedKeys();
    },
    // 提交
    handleSubmitPower() {
      console.log(this.$refs.tree.getCheckedKeys());
      roleMenuUpdate({
        id: this.powerId,
        menuIds: this.$refs.tree.getCheckedKeys(),
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("配置成功");
          this.showPower = false;
          this.onLoad();
          this.resetPower();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关闭
    handleClosePower() {
      this.showPower = false;
      this.resetPower();
    },
    resetPower() {
      this.powerId = "";
      this.defaultCheckedKeys = [];
    },
  },
};
</script>

<style scoped>
.dialogPower /deep/.el-dialog .el-dialog__body {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
</style>